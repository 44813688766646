import React from 'react';
import { useLocation } from '@reach/router';
import './grid.css';

const textColor = `#eee`

const REF_WIDTH = 30;

const Row = props => {
  return (
    <div style={{ fontWeight: 'bold', fontSize: '1.5rem', display: 'flex', flexDirection: 'row', height: props.height }}>{props.children}</div>
  )
}

const Cell = props => {
  const border = `solid 1px ${props.color}`
  // const borderTop = `solid 1px ${props.color}`
  // const borderBottom = `solid 1px ${props.color}`
  // const borderRight = `solid 1px ${props.color}`
  // const borderLeft = `solid 1px ${props.color}`
    return (
    <div style={{ color: props.color, textAlign: 'center', alignItems: 'center', justifyContent: 'center', border, display: 'flex', verticalAlign: 'middle', width: props.width, height: '100%'  }}>{props.children}</div>
  )
}

const letters = i => {
  return String.fromCharCode(i + 65);
}

const LettersRow = props => {
  const {grid} = props;
  return (
    <Row>
      <Cell key="f" {...props} width={REF_WIDTH} >&nbsp;</Cell>
      {grid[0].map((letter) => {
      return <Cell key={letter} {...props}>{letter}</Cell>
      })}
      <Cell key="l" {...props} width={REF_WIDTH} >&nbsp;</Cell>
    </Row>
  )
}

const TransparentLayout = props => {
  return (
    <React.Fragment>{props.children}</React.Fragment>
  )
}

const Page = (props) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const rows = parseInt((urlParams.get('rows') || 10), 10);
  const cols = parseInt((urlParams.get('cols') || 10), 10);
  const colorLine = urlParams.get('color') ? `#${urlParams.get('color')}` : textColor;
  const gridRows = Array.from(Array(rows), (x, i) => i + 1);
  const fullGrid = gridRows.map((r) => {
    return Array.from(Array(cols), (x,i) => letters(i))
  })
  const width = Math.floor(100 / cols + 2);
  const height = Math.floor(100 / rows + 2);
  return (
    <TransparentLayout>
      <div style={{display: 'flex', flexDirection:'column', width: '100vw', height: '100vh'}}>
        <LettersRow grid={fullGrid} width={`${width}%`} height={`${height}%`} color={colorLine} />
          {fullGrid.map((row, i) => {
            return (
              <Row key={i} height={`${height}%`} color={colorLine} >
                <Cell key={`${i}-first`} width={REF_WIDTH} height={`${height}%`} color={colorLine} >{i}</Cell>
                {row.map((e, j) => {
                  return <Cell key={j} width={`${width}%`} height={`${height}%`} color={colorLine} >&nbsp;</Cell>
                } )}
                <Cell key={`${i}-last`} width={REF_WIDTH} height={`${height}%`} color={colorLine} >{i}</Cell>
              </Row>
            )
          })}
        <LettersRow grid={fullGrid} width={`${width}%`} height={`${height}%`} color={colorLine} />
      </div>
    </TransparentLayout>
  )
}

export default Page;
